import React from 'react';



export default function Splash() {
    return <>
        <div className="screen">
            <div className="splash-content bg-gradients p-0">
                <div className="splash-wrapper">
                    <img src="images/logo.png" alt="logo" />
                    {/* <img src="images/jdtraderlogo.png" alt="logo" /> */}
                </div>
            </div>
        </div>
    </>
}
